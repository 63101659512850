<template>

<!-- <div v-if="errorAddDialogVisible">
    <ErrorAddModal :errorAddDialogVisible="errorAddDialogVisible" :closeErrorAddModal="closeErrorAddModal" />
</div> -->

<div v-if="errorAddDialogVisible === false" class="sm:block hidden">
  <el-dialog v-model="addToPlaylistModal" title="Ajouter à une playlist" width="40%" center :before-close="closeModal" destroy-on-close>
    <el-select @change="loadPlaylists" v-model="selectExistencePlaylist" placeholder="Nouvelle ou Anciennce playlist" class="w-full mb-2">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
    </el-select>
    <el-input v-if="selectExistencePlaylist == 'new'" placeholder="Donnez un nom à votre playlist" v-model="name"/>
    <div v-if="selectExistencePlaylist == 'old'" class="flex sm:flex-row flex-col justify-center items-start w-full mt-4">
          <el-table
            ref="singleTable"
            :data="selectedPlaylists"
            style="width: 100%"
            height="200"
            highlight-current-row
            @current-change="handlePlaylistChange"
          >
         
            <el-table-column label="Playlists">
              <template #default="scope">
                 <p class="p-2">{{ scope.row.name }}</p>
              </template>
            </el-table-column>          
          </el-table>
        </div>
    <template #footer>
      <span class="dialog-footer">
        <button @click="closeModal" class="py-2 px-4 mx-1 sm:my-0 my-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Annuler
        </button>
        <button @click="AddPlaylist"  class="py-2 px-5 mx-1 sm:my-0 my-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Valider
        </button>
      </span>
    </template>
  </el-dialog>
</div>
<div v-if="errorAddDialogVisible === false" class="sm:hidden block" >
  <el-dialog v-model="addToPlaylistModal" title="Ajouter à une playlist" width="85%" center :before-close="closeModal" destroy-on-close>
    <el-select @change="loadPlaylists" v-model="selectExistencePlaylist" placeholder="Nouvelle ou Anciennce playlist" class="w-full mb-2">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
    </el-select>
    <el-input v-if="selectExistencePlaylist == 'new'" placeholder="Donnez un nom à votre playlist" v-model="name"/>
    <div v-if="selectExistencePlaylist == 'old'" class="flex sm:flex-row flex-col justify-center items-start w-full mt-4">
          <el-table
            ref="singleTable"
            :data="selectedPlaylists"
            style="width: 100%"
            height="200"
            highlight-current-row
            @current-change="handlePlaylistChange"
          >
         
            <el-table-column label="Playlists">
              <template #default="scope">
                 <p class="p-2">{{ scope.row.name }}</p>
              </template>
            </el-table-column>          
          </el-table>
        </div>
    <template #footer>
      <span class="dialog-footer">
        <button @click="closeModal" class="py-2 px-4 mx-1 sm:my-0 my-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Annuler
        </button>
        <button @click="AddPlaylist"  class="py-2 px-5 mx-1 sm:my-0 my-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Valider
        </button>
      </span>
    </template>
  </el-dialog>
</div>

<div v-if="errorAddDialogVisible" class="sm:block hidden">
  <el-dialog v-model="addToPlaylistModal" title="Erreur d'ajout" width="40%" center :before-close="closeModal" destroy-on-close>
     <el-result
        icon="error"
        title="Erreur"
        sub-title="Cette question existe déjà dans cette playlist"
      >
        <template #extra>
          <button @click="closeErrorAddModal" class="py-2 px-4 mx-1 sm:my-0 my-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
            Retour
          </button>
        </template>
      </el-result>
  </el-dialog>
</div>

<div v-if="errorAddDialogVisible" class="sm:hidden block">
  <el-dialog v-model="addToPlaylistModal" title="Erreur d'ajout" width="85%" center :before-close="closeModal" destroy-on-close>
     <el-result
        icon="error"
        title="Erreur"
        sub-title="Cette question existe déjà dans cette playlist"
      >
        <template #extra>
          <button @click="closeErrorAddModal" class="py-2 px-4 mx-1 sm:my-0 my-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
           Retour
          </button>
        </template>
      </el-result>
  </el-dialog>
</div>

</template>

<script>
import axios from 'axios';
import ErrorAddModal from './ErrorAddModal.vue'
import { ElMessage } from 'element-plus'
import { h } from 'vue'
export default {
  components: {
    ErrorAddModal
  },
  data () {
    return {
      name: null,
      selectExistencePlaylist:null,
      errorAddDialogVisible : false,
      options: [
        {
          value: 'new',
          label: 'Nouvelle playlist',
        },
        {
          value: 'old',
          label: 'Ancienne playlist',
        },
       ],
       selectedPlaylists: [],
       selectedPlaylist: null
    }
  },

  
  props:{
    addToPlaylistModal : Boolean,
    closeAddToPlaylist: Function,
    selectedIdType:Object
  },

  methods: {
    closeModal(){
      this.closeAddToPlaylist()
    },

    handlePlaylistChange(val) {
      this.selectedPlaylist = {id : val.id , name : val.name };
    },

    AddPlaylist(){
      if(this.selectExistencePlaylist === "new" ){
        axios.post(process.env.VUE_APP_API_BASE_URL+"/createandaddsessionplaylist",
          {
            name: this.name,
            id_question : this.selectedIdType.id,
            type: this.selectedIdType.type,
            user_ID : this.User.id
          }, 
          {
            headers: {
              Authorization: "Bearer" + this.isToken,
            },
          })
          .then(({ data }) => {
            // this.isLoading = false;
            this.success(this.name)
            this.closeModal()
          })
          .catch(() => {
            // this.isLoading = false;
          });


      }else if(this.selectExistencePlaylist === "old" ){
        axios.get(process.env.VUE_APP_API_BASE_URL+"/sessionsplaylistsverify/"+this.selectedPlaylist.id+"/"+ this.selectedIdType.id+"/"+this.selectedIdType.type, 
          {
            headers: {
              Authorization: "Bearer" + this.isToken,
            },
          })
          .then(({ data }) => {
            if(data.exist === true){
              this.errorAddDialogVisible = true;
              // this.closeModal()
            }else{
              this.addToOldPlaylist();
              this.success(this.selectedPlaylist.name)
              // this.closeModal()
            }
            // this.isLoading = false;
            
          })
          .catch(() => {
            // this.isLoading = false;
          });

        
      }
    },
    
    closeErrorAddModal(){
      this.errorAddDialogVisible = false;
    },

     addToOldPlaylist(){
       axios.post(process.env.VUE_APP_API_BASE_URL+"/addquestionsessionplaylist",
          {
            id_session : this.selectedPlaylist.id,
            id_question : this.selectedIdType.id,
            type: this.selectedIdType.type,
          }, 
          {
            headers: {
              Authorization: "Bearer" + this.isToken,
            },
          })
          .then(({ data }) => {
            // this.isLoading = false;
            this.closeModal()
          })
          .catch(() => {
            // this.isLoading = false;
          });
     },
  

    loadPlaylists(){
      if(this.selectExistencePlaylist === "old" ){
       
        axios
            .get(process.env.VUE_APP_API_BASE_URL+"/sessionsplaylistsofuser/" +this.User.id +"/"+this.SubscribeNow, {
              headers: {
                Authorization: "Bearer" + this.isToken,
              },
            })
            .then(({ data }) => {
              this.selectedPlaylists = data.message
            })
            .catch(() => {
              // this.isLoading = false;
            });
      }
     
     
    },



  },


  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
    User() {
      return this.$store.getters.get_user;
    },
    SubscribeNow(){
          return this.$store.getters.get_subscribeNow
    },
  },

    setup() {

      const success = (name) => {
        ElMessage({
          message: h('p', { style: 'color: #65a30d' }, [
           
            h('span', null , 'La question a été ajouté à la playlist '),
            h('span', { style: 'font-weight: bold' }, name),
            h('span', null , ' avec succès'),
          ]),
          type: 'success',
        })
      }
      return{success}
      
    },
    

}
</script>

<style>

</style>